import React from "react"
import styled from "styled-components"

import { ContentGrid } from "./global"

const OuterWrapper = styled.div`
  grid-column: full-start / full-end;
  z-index: -1;

  img {
    height: auto;
    width: 100%;
  }

  @media ${props => props.theme.breakpoints.medium} {
    grid-column: wide-start / wide-end;
  }
`

const InnerWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
`

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background-image: linear-gradient(
    to bottom,
    rgba(34, 34, 34, 0.8),
    rgba(34, 34, 34, 0)
  );
  mix-blend-mode: multiply;

  @media ${props => props.theme.breakpoints.medium} {
    display: none;
  }
`

const FeaturedImageCaption = styled.div`
  grid-column: padding-start / padding-end;
  font-size: 0.85rem;
  font-style: italic;
  letter-spacing: 0.03em;
  opacity: 0.6;

  @media ${props => props.theme.breakpoints.medium} {
    grid-column: wide-start / wide-end;
  }
`

const FeaturedImage = ({ image, caption }) => {
  return (
    <ContentGrid>
      <OuterWrapper>
        <InnerWrapper>
          <img
            src={image?.url}
            alt={image.alt ? image.alt : ""}
            height={808}
            width={1447}
          />
          <ImageOverlay />
        </InnerWrapper>
      </OuterWrapper>
      <FeaturedImageCaption>{caption}</FeaturedImageCaption>
    </ContentGrid>
  )
}

export default FeaturedImage
