import React from "react"
import styled from "styled-components"

import { ContentGrid } from "./global"

import FeaturedImage from "./featuredImage"
import PublishDate from "./publishDate"

const TitleImageBlockWrapper = styled.div`
  margin-top: -${props => props.theme.measurements.header.mobileHeaderHeight};
  position: relative;
  z-index: -1;

  @media ${props => props.theme.breakpoints.medium} {
    margin-top: 0.7rem;
  }
`

const TitleAndPublishDate = styled.div`
  grid-column: content-start / content-end;
`

const TitleWrapper = styled.div`
  h1 {
    margin: 1.1em 0 0.1em;
  }
`

const TitleImageBlock = ({ title, image, caption, updatedDate }) => {
  return (
    <TitleImageBlockWrapper>
      {image && <FeaturedImage image={image} caption={caption} />}
      <ContentGrid>
        <TitleAndPublishDate>
          <TitleWrapper>
            <h1>{title}</h1>
          </TitleWrapper>
          <PublishDate updatedDate={updatedDate} />
        </TitleAndPublishDate>
      </ContentGrid>
    </TitleImageBlockWrapper>
  )
}

export default TitleImageBlock
