import React from "react"
import styled from "styled-components"

const VideoWrapper = styled.div`
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;

  iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const YouTubeEmbed = ({ url, width }) => {
  const correctedURL = url.replace("watch?v=", "embed/")

  return (
    <VideoWrapper>
      <iframe
        width="100%"
        height="500px"
        src={correctedURL}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </VideoWrapper>
  )
}

export default YouTubeEmbed
