import React from "react"
import styled from "styled-components"

import YouTubeEmbed from "./youTubeEmbed"

const SliceVideoWrapper = styled.div`
  margin: 2rem 0 2.8rem;
  grid-column: content-start / content-end;
`

const LocationWrapper = styled.div`
  color: ${props => props.theme.colors.teal};
`

const VideoTitle = styled.h2`
  margin: 0.25em 0 0.5em;
`

const SliceVideo = ({ video_title, location, video_url }) => {
  console.log("video_url", video_url)
  console.log("video_title", video_title)
  return (
    <SliceVideoWrapper>
      {location && <LocationWrapper>{location}</LocationWrapper>}

      {video_title ? (
        <VideoTitle>{video_title}</VideoTitle>
      ) : (
        <div>NO TITLE</div>
      )}

      <YouTubeEmbed url={video_url.embed_url} width={900} />
    </SliceVideoWrapper>
  )
}

export default SliceVideo
