import React from "react"
import styled from "styled-components"

import { RichText } from "prismic-reactjs"

const SliceContentWrapper = styled.div`
  grid-column: content-start / content-end;
`

const SliceContent = ({ content }) => {
  console.log("sliceContent", content)
  return (
    <SliceContentWrapper>
      <RichText render={content} />
    </SliceContentWrapper>
  )
}

export default SliceContent
