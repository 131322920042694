import React from "react"
import styled from "styled-components"

import { ContentGrid } from "./global"

const StyledContentGrid = styled(ContentGrid)`
  position: relative;

  &:before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    display: block;
    grid-column: full-start / full-end;
    background: ${props => props.theme.colors.text};
    z-index: -1;

    @media ${props => props.theme.breakpoints.medium} {
      grid-column: wide-start / wide-end;
    }
  }
`

const StyledReport = styled.div`
  margin: 1.5rem 0 3rem;
  color: ${props => props.theme.colors.bg};

  h3 {
    font-family: ${props => props.theme.fonts.body};
    /* font-family: serif; */
    line-height: 1.4;
    letter-spacing: 0.035em;
  }

  h4,
  .text-blue {
    color: #27d;
  }

  h4 {
    margin-top: 1.7em;
    font-family: ${props => props.theme.fonts.body};
    /* font-family: serif; */
    font-size: 1.15rem;
    font-weight: 400;
    line-height: 1.3;
    text-transform: initial;
  }

  h3 + h4 {
    margin-top: 1.1em;
  }

  ul,
  ol {
    padding-left: 1.5rem;

    @media ${props => props.theme.breakpoints.small} {
      padding-left: 2rem;
    }

    @media ${props => props.theme.breakpoints.medium} {
      padding-left: 2.5rem;
    }
  }

  li > ol > li:first-child {
    margin-top: 0.5rem;
  }

  strong {
    letter-spacing: 0.01em;
  }

  .underline-nice {
    /* border-bottom: 1px solid ${props => props.theme.colors.bg}; */
    box-shadow: inset 0 -1px 0 ${props => props.theme.colors.bg};
  }

  .hidden {
    display: none;
  }

  .text-body {
    font-family: ${props => props.theme.fonts.body};
  }

  .text-center {
    text-align: center;
  }

  .text-weight-normal {
    font-weight: normal;
  }

  .text-letter-spacing-normal {
    letter-spacing: 0;
  }

  .text-transform-none {
    text-transform: none;
  }

  .report-wrapper {
    padding: 0rem 0;
    background: ${props => props.theme.colors.text};

    &:before {
      content: "";
      background: ${props => props.theme.colors.text};
    }
  }

  .cover-page {
    margin-bottom: 5rem;
  }

  .cover-office {
    padding: 1.2rem 2rem;
    border-top: 1px solid ${props => props.theme.colors.bg};
    border-bottom: 1px solid ${props => props.theme.colors.bg};
    display: inline-block;
    font-size: 1.2rem;
    line-height: 1.4;
    letter-spacing: 0.02em;
  }

  .cover-title {
    margin: 2em 0;
    font-family: ${props => props.theme.fonts.body};
    font-size: 2rem;
    line-height: 1.2;
    letter-spacing: 0.02em;
    text-transform: none;
  }

  .cover-date {
    font-size: 1.5rem;
  }

  .report-inset {
    margin: 1.8rem 0;
    padding: 1.4rem;
    background: #e9e9e9;

    h4 {
      color: ${props => props.theme.colors.bg};
      font-weight: bold;
      letter-spacing: 0.03em;
    }

    @media ${props => props.theme.breakpoints.small} {
      padding: 1.7rem;
    }

    @media ${props => props.theme.breakpoints.medium} {
      padding: 2rem;
    }
  }

  .report-inset > *:first-child {
    margin-top: 0;
  }

  .report-inset > *:last-child {
    margin-bottom: 0;
  }

  .footnote-body {
    margin-left: 1px;
    top: -0.65em;
    font-size: 0.75em;
  }

  .footnote-footer-wrapper {
    margin-top: 2rem;
    position: relative;

    @media ${props => props.theme.breakpoints.large} {
      margin-top: 3rem;
    }
  }

  .footnote-footer-wrapper:before {
    content: "";
    height: 0;
    width: 30%;
    position: absolute;
    top: 0;
    border-top: 1px solid #fff;
  }

  .footnote-footer-wrapper p {
    padding: 0.6em 0 0;
  }

  .list-alpha {
    list-style-type: lower-alpha;
  }

  .unclassified-text {
    margin: 3rem 0;
    font-family: "Courier New", "Courier", monospace;

    @media ${props => props.theme.breakpoints.large} {
      margin: 4rem 0;
    }
  }

  @media ${props => props.theme.breakpoints.medium} {
    margin: 2.5rem 0 4rem;
  }
`

const PentagonReport = () => {
  return (
    <StyledReport>
      <StyledContentGrid>
        <div className="report-wrapper">
          <div
            className="report-page-wrapper cover-page text-center"
            id="id1_1"
          >
            <p className="unclassified-text">UNCLASSIFIED</p>
            <p className="cover-office">
              OFFICE OF THE DIRECTOR OF NATIONAL INTELLIGENCE
            </p>
            <h2 className="cover-title">
              Preliminary Assessment:
              <br />
              Unidentified Aerial Phenomena
            </h2>
            <p className="cover-date">25 June 2021</p>
            <p className="hidden">UNCLASSIFIED</p>
          </div>

          <div className="report-page-wrapper" id="page_2">
            <p className="hidden">UNCLASSIFIED</p>
            <h3>
              <span className="underline-nice">SCOPE AND ASSUMPTIONS</span>
            </h3>
            <h4>Scope</h4>
            <p>
              This preliminary report is provided by the Office of the Director
              of National Intelligence (ODNI) in response to the provision in
              Senate Report 116-233, accompanying the Intelligence Authorization
              Act (IAA) for Fiscal Year 2021, that the DNI, in consultation with
              the Secretary of Defense (SECDEF), is to submit an intelligence
              assessment of the threat posed by unidentified aerial phenomena
              (UAP) and the progress the Department of Defense Unidentified
              Aerial Phenomena Task Force (UAPTF) has made in understanding this
              threat.
            </p>
            <p>
              This report provides an overview for policymakers of the
              challenges associated with characterizing the potential threat
              posed by UAP while also providing a means to develop relevant
              processes, policies, technologies, and training for the U.S.
              military and other U.S. Government (USG) personnel if and when
              they encounter UAP, so as to enhance the Intelligence Community’s
              (IC) ability to understand the threat. The Director, UAPTF, is the
              accountable official for ensuring the timely collection and
              consolidation of data on UAP. The dataset described in this report
              is currently limited primarily to U.S. Government reporting of
              incidents occurring from November 2004 to March 2021. Data
              continues to be collected and analyzed.
            </p>
            <p>
              ODNI prepared this report for the Congressional Intelligence and
              Armed Services Committees. UAPTF and the ODNI National
              Intelligence Manager for Aviation drafted this report, with input
              from USD(I&S), DIA, FBI, NRO, NGA, NSA, Air Force, Army, Navy,
              Navy/ONI, DARPA, FAA, NOAA, NGA, ODNI/NIM-Emerging and Disruptive
              Technology, ODNI/National Counterintelligence and Security Center,
              and ODNI/National Intelligence Council.
            </p>
            <h4>Assumptions</h4>
            <p>
              Various forms of sensors that register UAP generally operate
              correctly and capture enough real data to allow initial
              assessments, but some UAP may be attributable to sensor anomalies.
            </p>
            <p className="hidden">2</p>
            <p className="hidden">UNCLASSIFIED</p>
          </div>
          <div className="report-page-wrapper" id="page_3">
            <p className="hidden">UNCLASSIFIED</p>
            <h3>
              <span className="underline-nice">EXECUTIVE SUMMARY</span>
            </h3>
            <p>
              <strong>
                The limited amount of high-quality reporting on unidentified
                aerial phenomena (UAP) hampers our ability to draw firm
                conclusions about the nature or intent of UAP.
              </strong>{" "}
              The Unidentified Aerial Phenomena Task Force (UAPTF) considered a
              range of information on UAP described in U.S. military and IC
              (Intelligence Community) reporting, but because the reporting
              lacked sufficient specificity, ultimately recognized that a
              unique, tailored reporting process was required to provide
              sufficient data for analysis of UAP events.
            </p>
            <ul>
              <li>
                As a result, the UAPTF concentrated its review on reports that
                occurred between 2004 and 2021, the majority of which are a
                result of this new tailored process to better capture UAP events
                through formalized reporting.
              </li>
              <li>
                Most of the UAP reported probably do represent physical objects
                given that a majority of UAP were registered across multiple
                sensors, to include radar, infrared, electro-optical, weapon
                seekers, and visual observation.
              </li>
            </ul>
            <p>
              <strong>
                In a limited number of incidents, UAP reportedly appeared to
                exhibit unusual flight characteristics. These observations could
                be the result of sensor errors, spoofing, or observer
                misperception and require additional rigorous analysis.
              </strong>
            </p>
            <p>
              <strong>
                There are probably multiple types of UAP requiring different
                explanations based on the range of appearances and behaviors
                described in the available reporting.
              </strong>{" "}
              Our analysis of the data supports the construct that if and when
              individual UAP incidents are resolved they will fall into one of
              five potential explanatory categories: airborne clutter, natural
              atmospheric phenomena, USG or U.S. industry developmental
              programs, foreign adversary systems, and a catchall “other” bin.
            </p>
            <p>
              <strong>
                UAP clearly pose a safety of flight issue and may pose a
                challenge to U.S. national security
              </strong>
              . Safety concerns primarily center on aviators contending with an
              increasingly cluttered air domain. UAP would also represent a
              national security challenge if they are foreign adversary
              collection platforms or provide evidence a potential adversary has
              developed either a breakthrough or disruptive technology.
            </p>
            <p>
              <strong>
                Consistent consolidation of reports from across the federal
                government, standardized reporting, increased collection and
                analysis, and a streamlined process for screening all such
                reports against a broad range of relevant USG data will allow
                for a more sophisticated analysis of UAP that is likely to
                deepen our understanding.
              </strong>{" "}
              Some of these steps are resource-intensive and would require
              additional investment.
            </p>
            <p className="hidden">3</p>
            <p className="hidden">UNCLASSIFIED</p>
          </div>
          <div className="report-page-wrapper" id="page_4">
            <p className="hidden">UNCLASSIFIED</p>
            <h3>
              <span className="underline-nice">
                AVAILABLE REPORTING LARGELY INCONCLUSIVE
              </span>
            </h3>
            <h4>Limited Data Leaves Most UAP Unexplained…</h4>
            <p>
              Limited data and inconsistency in reporting are key challenges to
              evaluating UAP. No standardized reporting mechanism existed until
              the Navy established one in March 2019. The Air Force subsequently
              adopted that mechanism in November 2020, but it remains limited to
              USG reporting. The UAPTF regularly heard anecdotally during its
              research about other observations that occurred but which were
              never captured in formal or informal reporting by those observers.
            </p>
            <p>
              After carefully considering this information, the UAPTF focused on
              reports that involved UAP largely witnessed firsthand by military
              aviators and that were collected from systems we considered to be
              reliable. These reports describe incidents that occurred between
              2004 and 2021, with the majority coming in the last two years as
              the new reporting mechanism became better known to the military
              aviation community. We were able to identify one reported UAP with
              high confidence. In that case, we identified the object as a
              large, deflating balloon. The others remain unexplained.
            </p>
            <ul>
              <li>
                <strong>144</strong> reports originated from USG sources. Of
                these, <strong>80</strong> reports involved observation with
                multiple sensors.
                <ul>
                  <li>
                    Most reports described UAP as objects that interrupted
                    pre-planned training or other military activity.
                  </li>
                </ul>
              </li>
            </ul>
            <div className="report-inset">
              <h4>UAP Collection Challenges</h4>
              <p>
                Sociocultural stigmas and sensor limitations remain obstacles to
                collecting data on UAP. Although some technical challenges—such
                as how to appropriately filter out radar clutter to ensure
                safety of flight for military and civilian aircraft—are
                longstanding in the aviation community, while others are unique
                to the UAP problem set.
              </p>
              <ul>
                <li>
                  Narratives from aviators in the operational community and
                  analysts from the military and IC describe disparagement
                  associated with observing UAP, reporting it, or attempting to
                  discuss it with colleagues. Although the effects of these
                  stigmas have lessened as senior members of the scientific,
                  policy, military, and intelligence communities engage on the
                  topic seriously in public, reputational risk may keep many
                  observers silent, complicating scientific pursuit of the
                  topic.
                </li>
                <li>
                  The sensors mounted on U.S. military platforms are typically
                  designed to fulfill specific missions. As a result, those
                  sensors are not generally suited for identifying UAP.
                </li>
                <li>
                  Sensor vantage points and the numbers of sensors concurrently
                  observing an object play substantial roles in distinguishing
                  UAP from known objects and determining whether a UAP
                  demonstrates breakthrough aerospace capabilities. Optical
                  sensors have the benefit of providing some insight into
                  relative size, shape, and structure. Radiofrequency sensors
                  provide more accurate velocity and range information.
                </li>
              </ul>
            </div>
            <p className="hidden">4</p>
            <p className="hidden">UNCLASSIFIED</p>
          </div>
          <div className="report-page-wrapper" id="page_5">
            <h4>But Some Potential Patterns Do Emerge</h4>
            <p>
              Although there was wide variability in the reports and the dataset
              is currently too limited to allow for detailed trend or pattern
              analysis, there was some clustering of UAP observations regarding
              shape, size, and, particularly, propulsion. UAP sightings also
              tended to cluster around U.S. training and testing grounds, but we
              assess that this may result from a collection bias as a result of
              focused attention, greater numbers of latest-generation sensors
              operating in those areas, unit expectations, and guidance to
              report anomalies.
            </p>
            <h4>
              And a Handful of UAP Appear to Demonstrate Advanced Technology
            </h4>
            <p>
              In <strong>18</strong> incidents, described in <strong>21</strong>{" "}
              reports, observers reported unusual UAP movement patterns or
              flight characteristics.
            </p>
            <p>
              Some UAP appeared to remain stationary in winds aloft, move
              against the wind, maneuver abruptly, or move at considerable
              speed, without discernable means of propulsion. In a small number
              of cases, military aircraft systems processed radio frequency (RF)
              energy associated with UAP sightings.
            </p>
            <p>
              The UAPTF holds a small amount of data that appear to show UAP
              demonstrating acceleration or a degree of signature management.
              Additional rigorous analysis are necessary by multiple teams or
              groups of technical experts to determine the nature and validity
              of these data. We are conducting further analysis to determine if
              breakthrough technologies were demonstrated.
            </p>
            <h3>
              <span className="underline-nice">
                UAP PROBABLY LACK A SINGLE EXPLANATION
              </span>
            </h3>
            <p>
              The UAP documented in this limited dataset demonstrate an array of
              aerial behaviors, reinforcing the possibility there are multiple
              types of UAP requiring different explanations. Our analysis of the
              data supports the construct that if and when individual UAP
              incidents are resolved they will fall into one of five potential
              explanatory categories: airborne clutter, natural atmospheric
              phenomena, USG or industry developmental programs, foreign
              adversary systems, and a catchall “other” bin. With the exception
              of the one instance where we determined with high confidence that
              the reported UAP was airborne clutter, specifically a deflating
              balloon, we currently lack sufficient information in our dataset
              to attribute incidents to specific explanations.
            </p>
            <p>
              <span className="text-blue">Airborne Clutter: </span>These objects
              include birds, balloons, recreational unmanned aerial vehicles
              (UAV), or airborne debris like plastic bags that muddle a scene
              and affect an operator’s ability to identify true targets, such as
              enemy aircraft.
            </p>
            <p>
              <span className="text-blue">Natural Atmospheric Phenomena: </span>
              Natural atmospheric phenomena includes ice crystals, moisture, and
              thermal fluctuations that may register on some infrared and radar
              systems.
            </p>
            <p>
              <span className="text-blue">
                USG or Industry Developmental Programs:{" "}
              </span>
              Some UAP observations could be attributable to developments and
              classified programs by U.S. entities. We were unable to confirm,
              however, that these systems accounted for any of the UAP reports
              we collected.
            </p>
            <p>
              <span className="text-blue">Foreign Adversary Systems: </span>Some
              UAP may be technologies deployed by China, Russia, another nation,
              or a non-governmental entity.
            </p>
            <p className="hidden">5</p>
            <p className="hidden">UNCLASSIFIED</p>
          </div>
          <div className="report-page-wrapper" id="page_6">
            <p className="hidden">UNCLASSIFIED</p>
            <p>
              <span className="text-blue">Other: </span>Although most of the UAP
              described in our dataset probably remain unidentified due to
              limited data or challenges to collection processing or analysis,
              we may require additional scientific knowledge to successfully
              collect on, analyze and characterize some of them. We would group
              such objects in this category pending scientific advances that
              allowed us to better understand them. The UAPTF intends to focus
              additional analysis on the small number of cases where a UAP
              appeared to display unusual flight characteristics or signature
              management.
            </p>
            <h3>
              <span className="underline-nice">
                UAP THREATEN FLIGHT SAFETY AND, POSSIBLY, NATIONAL SECURITY
              </span>
            </h3>
            <p>
              UAP pose a hazard to safety of flight and could pose a broader
              danger if some instances represent sophisticated collection
              against U.S. military activities by a foreign government or
              demonstrate a breakthrough aerospace technology by a potential
              adversary.
            </p>
            <h4>Ongoing Airspace Concerns</h4>
            <p>
              When aviators encounter safety hazards, they are required to
              report these concerns. Depending on the location, volume, and
              behavior of hazards during incursions on ranges, pilots may cease
              their tests and/or training and land their aircraft, which has a
              deterrent effect on reporting.
            </p>
            <ul>
              <li>
                The UAPTF has 11 reports of documented instances in which pilots
                reported near misses with a UAP.
              </li>
            </ul>
            <h4>Potential National Security Challenges</h4>
            <p>
              We currently lack data to indicate any UAP are part of a foreign
              collection program or indicative of a major technological
              advancement by a potential adversary. We continue to monitor for
              evidence of such programs given the counter intelligence challenge
              they would pose, particularly as some UAP have been detected near
              military facilities or by aircraft carrying the USG’s most
              advanced sensor systems.
            </p>
            <h3>
              <span className="underline-nice">
                EXPLAINING UAP WILL REQUIRE ANALYTIC, COLLECTION AND RESOURCE
                INVESTMENT
              </span>
            </h3>
            <h4>
              Standardize the Reporting, Consolidate the Data, and Deepen the
              Analysis
            </h4>
            <p>
              In line with the provisions of Senate Report 116-233, accompanying
              the IAA for FY 2021, the UAPTF’s long-term goal is to widen the
              scope of its work to include additional UAP events documented by a
              broader swath of USG personnel and technical systems in its
              analysis. As the dataset increases, the UAPTF’s ability to employ
              data analytics to detect trends will also improve. The initial
              focus will be to employ artificial intelligence/machine learning
              algorithms to cluster and recognize similarities and patterns in
              features of the data points. As the database accumulates
              information from known aerial objects such as weather balloons,
              high-altitude or super-pressure balloons, and wildlife, machine
              learning can add efficiency by pre-assessing UAP reports to see if
              those records match similar events already in the database.
            </p>
            <ul>
              <li>
                The UAPTF has begun to develop interagency analytical and
                processing workflows to ensure both collection and analysis will
                be well informed and coordinated.
              </li>
            </ul>
            <p className="hidden">6</p>
            <p className="hidden">UNCLASSIFIED</p>
          </div>
          <div className="report-page-wrapper" id="page_7">
            <p className="hidden">UNCLASSIFIED</p>
            <p>
              The majority of UAP data is from U.S. Navy reporting, but efforts
              are underway to standardize incident reporting across U.S.
              military services and other government agencies to ensure all
              relevant data is captured with respect to particular incidents and
              any U.S. activities that might be relevant. The UAPTF is currently
              working to acquire additional reporting, including from the U.S.
              Air Force (USAF), and has begun receiving data from the Federal
              Aviation Administration (FAA).
            </p>
            <ul>
              <li>
                Although USAF data collection has been limited historically the
                USAF began a six- month pilot program in November 2020 to
                collect in the most likely areas to encounter UAP and is
                evaluating how to normalize future collection, reporting, and
                analysis across the entire Air Force.
              </li>
              <li>
                The FAA captures data related to UAP during the normal course of
                managing air traffic operations. The FAA generally ingests this
                data when pilots and other airspace users report unusual or
                unexpected events to the FAA’s Air Traffic Organization.
              </li>
              <li>
                In addition, the FAA continuously monitors its systems for
                anomalies, generating additional information that may be of use
                to the UAPTF. The FAA is able to isolate data of interest to the
                UAPTF and make it available. The FAA has a robust and effective
                outreach program that can help the UAPTF reach members of the
                aviation community to highlight the importance of reporting UAP.
              </li>
            </ul>
            <h4>Expand Collection</h4>
            <p>
              The UAPTF is looking for novel ways to increase collection of UAP
              cluster areas when U.S. forces are not present as a way to
              baseline “standard” UAP activity and mitigate the collection bias
              in the dataset. One proposal is to use advanced algorithms to
              search historical data captured and stored by radars. The UAPTF
              also plans to update its current interagency UAP collection
              strategy in order bring to bear relevant collection platforms and
              methods from the DoD and the IC.
            </p>
            <h4>Increase Investment in Research and Development</h4>
            <p>
              The UAPTF has indicated that additional funding for research and
              development could further the future study of the topics laid out
              in this report. Such investments should be guided by a UAP
              Collection Strategy, UAP R&amp;D Technical Roadmap, and a UAP
              Program Plan.
            </p>
            <p className="hidden">7</p>
            <p className="hidden">UNCLASSIFIED</p>
          </div>
          <div className="report-page-wrapper" id="page_8">
            <p className="hidden">UNCLASSIFIED</p>
            <h3>
              <span className="underline-nice">APPENDIX A</span>
              <span> - </span>
              <span className="text-body text-weight-normal text-letter-spacing-normal text-blue text-transform-none">
                Definition of Key Terms
              </span>
            </h3>
            <p>
              This report and UAPTF databases use the following defining terms:
            </p>
            <p>
              <strong>Unidentified Aerial Phenomena (UAP):</strong> Airborne
              objects not immediately identifiable. The acronym UAP represents
              the broadest category of airborne objects reviewed for analysis.
            </p>
            <p>
              <strong>UAP Event:</strong> A holistic description of an
              occurrence during which a pilot or aircrew witnessed (or detected)
              a UAP.
            </p>
            <p>
              <strong>UAP Incident:</strong> A specific part of the event.
            </p>
            <p>
              <strong>UAP Report:</strong> Documentation of a UAP event, to
              include verified chains of custody and basic information such as
              the time, date, location, and description of the UAP. UAP reports
              include Range Fouler<sup className="footnote-body">1</sup> reports
              and other reporting.
            </p>
            <div id="footnote1" className="footnote-footer-wrapper">
              <p>
                <sup>1</sup> U.S. Navy aviators define a “range fouler” as an
                activity or object that interrupts pre-planned training or other
                military activity in a military operating area or restricted
                airspace.
              </p>
            </div>
            <p className="hidden">8</p>
            <p className="hidden">UNCLASSIFIED</p>
          </div>
          <div className="report-page-wrapper" id="page_9">
            <p className="hidden">UNCLASSIFIED</p>
            <h3>
              <span className="underline-nice">APPENDIX B</span>
              <span> – </span>
              <span className="text-body text-weight-normal text-letter-spacing-normal text-blue text-transform-none">
                Senate Report Accompanying the Intelligence Authorization Act
                for Fiscal Year 2021
              </span>
            </h3>
            <p>
              Senate Report 116-233, accompanying the Intelligence Authorization
              Act for Fiscal Year 2021, provides that the DNI, in consultation
              with the SECDEF and other relevant heads of USG Agencies, is to
              submit an intelligence assessment of the threat posed by UAP and
              the progress the UAPTF has made to understand this threat.
            </p>
            <p>
              The Senate Report specifically requested that the report include:
            </p>
            <ol>
              <li>
                A detailed analysis of UAP data and intelligence reporting
                collected or held by the Office of Naval Intelligence, including
                data and intelligence reporting held by the UAPTF;
              </li>
              <li>
                A detailed analysis of unidentified phenomena data collected by:
                <ol className="list-alpha">
                  <li>Geospatial Intelligence;</li>
                  <li>Signals Intelligence;</li>
                  <li>Human Intelligence; and</li>
                  <li>Measurement and Signatures Intelligence</li>
                </ol>
              </li>
              <li>
                A detailed analysis of data of the Federal Bureau of
                Investigation, which was derived from investigations of
                intrusions of UAP data over restricted U.S. airspace;
              </li>
              <li>
                A detailed description of an interagency process for ensuring
                timely data collection and centralized analysis of all UAP
                reporting for the Federal Government, regardless of which
                service or agency acquired the information;
              </li>
              <li>
                Identification of an official accountable for the process
                described in paragraph 4;
              </li>
              <li>
                Identification of potential aerospace or other threats posed by
                the UAP to national security, and an assessment of whether this
                UAP activity may be attributed to one or more foreign
                adversaries;
              </li>
              <li>
                Identification of any incidents or patterns that indicate a
                potential adversary, have achieved breakthrough aerospace
                capabilities that could put U.S. strategic or conventional
                forces at risk; and
              </li>
              <li>
                Recommendations regarding increased collection of data, enhanced
                research and development, additional funding, and other
                resources.
              </li>
            </ol>
            <p className="hidden">9</p>
            <p className="text-center unclassified-text">UNCLASSIFIED</p>
          </div>
        </div>
      </StyledContentGrid>
    </StyledReport>
  )
}

export default PentagonReport
