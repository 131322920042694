import React from "react"
import styled from "styled-components"

// make sure to import all components that are indicated by component_name in Prismic
import PentagonReport from "./pentagonReport"

const SliceContentWrapper = styled.div`
  grid-column: full-start / full-end;
`

const SliceContent = ({ component_name }) => {
  const ComponentName = PentagonReport

  return (
    <SliceContentWrapper>
      {!!component_name && <ComponentName />}
    </SliceContentWrapper>
  )
}

export default SliceContent
