import React from "react"
import styled from "styled-components"

import { formatDate } from "../utils/utils"

const UpdatedDateWrapper = styled.div`
  font-size: 0.9rem;
  letter-spacing: 0.025em;
  opacity: 0.6;
`

const PublishDate = ({ updatedDate }) => {
  const formattedDate = formatDate(updatedDate)

  return <UpdatedDateWrapper>Last updated: {formattedDate}</UpdatedDateWrapper>
}

export default PublishDate
