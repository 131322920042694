const formatDate = rawDate => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const ms = Date.parse(rawDate)
  const date = new Date(ms)
  const year = date.getFullYear()
  const month = months[date.getMonth()]
  const day = date.getDate()

  return `${month} ${day}, ${year}`
}

export { formatDate }
