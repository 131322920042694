import React from "react"
import styled from "styled-components"

import { ContentGrid } from "./global"

import SliceContent from "./_sliceContent"
import SliceVideo from "./_sliceVideo"
import SliceReactComponent from "./_sliceReactComponent"

const StyledContentGrid = styled(ContentGrid)`
  /* put body content styles here */

  li:not(:last-child) {
    margin-bottom: 0.7rem;
  }

  a {
    color: #00ffc2;
    color: ${props => props.theme.colors.teal};
    text-decoration: none;
  }

  a:hover,
  a:focus {
    box-shadow: inset 0 -1px 0 ${props => props.theme.colors.teal};
  }
`

const Content = ({ body }) => {
  return (
    <StyledContentGrid>
      {body
        ? body.map((slice, index) => {
            if (slice.type === "content_block")
              return (
                <SliceContent key={index} content={slice.primary.content} />
              )
            if (slice.type === "video_block") {
              console.log("video_block", slice)
              return (
                <SliceVideo
                  key={index}
                  video_title={slice.primary.video_title}
                  location={slice.primary.location}
                  video_url={slice.primary.video_url}
                />
              )
            }
            if (slice.type === "react_component") {
              return (
                <SliceReactComponent
                  key={index}
                  component_name={slice.primary.component_name}
                />
              )
            }
          })
        : null}
    </StyledContentGrid>
  )
}

export default Content
